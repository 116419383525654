<template>
    <div class="page-table sozlesmeTanim  mainDiv mb-30" id="affix-container" >
        <div class="animated fadeInUp card-base baslikBg">
            Sözleşme Güncelle
        </div>
        
        <el-row :gutter="10" v-loading="loading"
            :element-loading-text='$t("src.views.apps.sozlesme.tanimlama.addLoading")'
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)">
            <el-form status-icon :model="sozlesmeForm" :rules="rulesSozlesme" ref="sozlesmeForm" class="demo-ruleForm">
                <el-col :lg="18" :md="18" :sm="24" :xs="24">                        
                        <div class="card-base card-shadow--medium demo-box bg-white animated fadeInLeft pb-20 pt-10" >
                            <el-row>
                                <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                                    <el-form-item label="Sözleşme Kategori" prop="sozlesmeKategoriID">:
                                        <el-select style="width: 100%" size="small"  v-model="sozlesmeForm.sozlesmeKategoriID" placeholder="Sözleşme Kategori Seçiniz" filterable :filter-methos="filterMethod">
                                          <el-option
                                              v-for="item in kategoriler" 
                                              :key="item.sozlesmeKategoriID"
                                              :label="item.sozlesmeKategoriAdi"
                                              :value="item.sozlesmeKategoriID">
                                          </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="16" :md="16" :sm="24" :xs="24" class="col-p">
                                    <el-form-item label="Sözleşme Kapsamı" prop="sozlesmeBaslik">:
                                        <el-input size="small"
                                            v-model="sozlesmeForm.sozlesmeBaslik"
                                            :placeholder="'Sözleşme Kapsamı Giriniz'"></el-input>
                                    </el-form-item>                                    
                                </el-col>
                             </el-row>   

                             <el-row>
                                <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                                    <el-form-item label="Yüzde Değer" prop="yuzdeDeger">:
                                        <el-input size="small"
                                            v-model="sozlesmeForm.yuzdeDeger"
                                            @input="formatYuzdeDeger"
                                            :placeholder="'Yüzde Değer Giriniz'"></el-input>
                                    </el-form-item>
                                </el-col>  

                                <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                                    <el-form-item label="Tahmini Başlama Süresi" prop="baslangicSuresi">:
                                        <el-input size="small"
                                            v-model="sozlesmeForm.baslangicSuresi"
                                            v-mask="'##########'"
                                            :placeholder="'Kaç Gün Sonra Başlayacağınızı Belirtiniz'"></el-input>
                                    </el-form-item>
                                </el-col>  

                                <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                                    <el-form-item label="Tahmini İş Süresi" prop="isSuresi">:
                                        <el-input size="small"
                                            v-model="sozlesmeForm.isSuresi"
                                            v-mask="'##########'"
                                            :placeholder="'Kaç Günde Biteceğini Belirtiniz'"></el-input>
                                    </el-form-item>
                                </el-col>  
                            </el-row>
                            <!--
                            <el-row>
                                <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                                        <el-form-item label="Sözleşmeye atamak istediğiniz rengi seçiniz:" prop="renkKodu"><br>
                                            <el-color-picker size="medium" v-model="sozlesmeForm.renkKodu" ></el-color-picker>                                   
                                         </el-form-item> 
                                </el-col>  

                                <el-col :lg="16" :md="16" :sm="24" :xs="24" class="col-p">
                                    <el-form-item label="Renk Kodu" prop="renkKodu">:
                                        <el-input size="small"
                                            type="text"
                                            :placeholder="'Renk Kodunu Hexadecimal Olarak Giriniz'"
                                            v-model="sozlesmeForm.renkKodu">
                                            </el-input>
                                    </el-form-item>
                                </el-col>  
                            </el-row>-->

                        </div>
                </el-col>

                <el-col :lg="6" :md="6" :sm="24" :xs="24" v-if="yetkiTuru == 1 || (yetkiTuru == 2 && yetkiListesi.includes('YK-sozlesmeU100'))">
                    <el-collapse class="yanForm animated fadeInUp" v-model="activeCollapse">
                        <el-collapse-item class="card-shadow--medium" name="8" :title='"Güncelle"'>
                            <el-row>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24">
                                    <el-button size="mini" v-on:click="sozlesmeGuncelle('sozlesmeForm')" class="onayBtn" type="primary">
                                        <label>Güncelle</label>
                                    </el-button>
                                </el-col>
                            </el-row>
                        </el-collapse-item>
                    </el-collapse>
                </el-col>
                
            </el-form>
        </el-row>
    </div>
</template>
<script>

    import sozlesmeService from '../../../WSProvider/SozlesmeService'
    import notification from '../../../notification'
    import functions from '../../../functions'
    import EventBus from '@/components/event-bus'

    export default {
        name: "SozlesmeGuncelle",
        components: {
        },

        created(){
            if (localStorage.getItem("userDataBGSurec")) {
                this.yetkiTuru = this.$store.getters.getYetkiTuru; 
                this.yetkiListesi = this.$store.getters.getYetkiListesi;
                //console.log(this.yetkiListesi)
                
            }
        },

        computed: {
            storeData(){
                //console.log("storeData computed girdi")
                return this.$store.getters.getSozlesmeUpdateData
            }
        },
        watch: {            
            storeData(val){
                //console.log("storeData watch girdi")
                this.sozlesmeForm.sozlesmeID = this.$store.getters.getSozlesmeUpdateData
                this.getSozlesmeDetay();
            }
        },        
        mounted() {
            this.sozlesmeForm.sozlesmeID = this.$store.getters.getSozlesmeUpdateData
            this.getSozlesmeDetay();
            this.sozlesmeKategoriListesi();
        },
        data() {
            return {
                loading : false,
                addLoading: false,
                yetkiListesi: [],                
                kategoriler: [],
                yetkiTuru: "",
                activeCollapse: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],                
                sozlesmeData: {},
                sozlesmeForm: {
                    sozlesmeKategoriAdi: "",
                    sozlesmeKategoriID: "",
                    sozlesmeBaslik:'',
                    yuzdeDeger:'',
                    baslangicSuresi:'',
                    isSuresi:'',
                    //renkKodu: '#ffffff',
                    durum: '1'                    
                },
                rulesSozlesme: {
                    sozlesmeBaslik: [{
                        required: true,
                        message: "Lütfen Sözleşme Giriniz",
                        trigger: 'blur'
                    }],
                    yuzdeDeger:[{
                        required: true,
                        message: "Lütfen Yüzde Değer Giriniz",
                        trigger: 'blur'
                    }],
                    baslangicSuresi:[{
                        required: true,
                        message: "Lütfen Başlama Değeri Giriniz",
                        trigger: 'blur'
                    }],
                    isSuresi:[{
                        required: true,
                        message: "Lütfen İş Süresi Giriniz",
                        trigger: 'blur'
                    }],
                },
            }
        },
        methods: {
            formatYuzdeDeger() {
                this.sozlesmeForm.yuzdeDeger = (this.sozlesmeForm.yuzdeDeger.replace(/\D/g, "").replace(/\B(?=(\d{2})+(?!\d))/g, "."));
            },
            
            filterMethod(query, item) {
                return item.label.toLowerCase().indexOf(query.toLowerCase()) >= 0;
            },

            getSozlesmeDetay(){
                try{
                    this.loading = true;
                    sozlesmeService.sozlesmeDetay(this.sozlesmeForm.sozlesmeID).then(response => {
                    //    console.log("getsozlesmeDetay girdi")
                        if(response.status == 200){
                            localStorage.setItem("userDataBGSurec", response.token);
                            
                            this.sozlesmeForm.sozlesmeKategoriID = response.data.sozlesmeKategoriID
                            this.sozlesmeForm.sozlesmeKategoriAdi = response.data.sozlesmeKategoriAdi
                            this.sozlesmeForm.sozlesmeBaslik = response.data.sozlesmeBaslik
                            this.sozlesmeForm.yuzdeDeger = response.data.yuzdeDeger
                            this.sozlesmeForm.baslangicSuresi = response.data.baslangicSuresi
                            this.sozlesmeForm.isSuresi = response.data.isSuresi
                           // this.sozlesmeForm.renkKodu = response.data.renkKodu
                            this.sozlesmeForm.durum =response.data.durum

                            this.sozlesmeData = response.data                            
                        }
                        this.loading = false;
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }
                            else notification.Status("errorMsg", this, error.errMsg)
                        }else{
                            //notification.Status(503, this)
                        }
                        this.loading = false;
                    })
                }catch(e){
                    this.loading = false;
                }
            },
            
            sozlesmeKategoriListesi() {
                try{
                    this.loading = true;
                    sozlesmeService.sozlesmeKategoriListesi().then((response) => {
                        localStorage.setItem("userDataBGSurec", response.token)
                        if (response.status == 200) {
                            this.kategoriler = response.data;
                        }
                        this.loading = false;
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){
                                this.kategoriler = [];
                            }
                            else notification.Status("errorMsg", this, error.errMsg)
                        }else{
                            //notification.Status(503, this)
                        }
                        this.loading = false;
                    })
                }catch(e){
                    // notification.Status("success", this, "An error occurred while fetching the customer list.")
                    this.loading = false
                }
            },

            sozlesmeGuncelle(formName) {  
               // console.log("sozlesmeGuncelle girdi")
                this.$refs[formName].validate((valid, errors) => {
                    if (valid) {
                        this.$confirm("Sözleşmeyi güncellemek istediğinize emin misiniz?", this.$t("src.views.apps.genel.uyari"), {
                            confirmButtonText: this.$t("src.views.apps.genel.yes"),
                            cancelButtonText: this.$t("src.views.apps.genel.no"),
                            type: 'warning'
                        }).then(() => {
                            
                            this.addLoading = true;

                            var update = functions.findObjectDifference(this.sozlesmeData, this.sozlesmeForm);

                            sozlesmeService.sozlesmeGuncelle(this.sozlesmeForm.sozlesmeID, update).then((response) => {
                                if(response.status == 200){
                                    localStorage.setItem("userDataBGSurec", response.token)
                                    notification.Status("success", this, response.msg)
                                    EventBus.$emit("sozlesmelistesi", true)
                                    functions.sayfaKapat("sozlesmeguncelle", this);
                                    this.resetForm(formName)
                                }
                                this.addLoading = false;
                            }).catch(err => {
                                if(err.responseJSON){
                                    let error = err.responseJSON
                                    if(error.status == 401){
                                        notification.Status(150, this);
                                    }
                                    else notification.Status("errorMsg", this, error.errMsg)
                                }else{
                                    //notification.Status(503, this)
                                }
                                this.addLoading = false;
                            })
                        }).catch((e) => {})
                    }else{
                        for(var k in errors){
                            errors[k].forEach((element) => {
                                notification.Status( "warning", this, element.message);
                            })  
                            this.loading = false
                        }
                    }
                })
            },

            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
        }
    }

</script>
